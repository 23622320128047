import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import {Route, Switch} from "react-router-dom";

import "assets/vendor/style";
import "styles/index.less";
import "./assets/fonts/index.less"
import 'assets/css/bootstrap5.min.css'
import configureStore, { history } from './redux/store';
import App from "./containers/App/index";

const store = configureStore(/ provide initial state if any /);

const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>;


export default NextApp;
