import React from "react";
import {  useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  TAB_SIZE,
} from "constants/ThemeSetting";
import LogoImg from 'assets/images/GWTC-logo.png'

const SidebarLogo = () => {
  
  const { width } = useSelector(({ settings }) => settings);
  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }

  const styles={
    container : {
      padding: '10px 30px 10px 22px'
    }
  }
  return (
    <div className="gx-layout-sider-header">
      {/* {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar">
        <i
          className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
          onClick={() => {
            if (navStyle === NAV_STYLE_DRAWER) {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            } else if (navStyle === NAV_STYLE_FIXED) {
              dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR))
            } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            } else {
              dispatch(onNavStyleChange(NAV_STYLE_FIXED))
            }
          }}
        />
      </div> : null} */}

      <Link to="/" className="gx-site-logo">
        
          <div className="gx-d-flex gx-align-items-center">
            <img src={"http://greenwichtc.com/assets/images/Logos/WSO-logo.png"} alt="loco" style={{width : '50px', height : '50px', marginRight : 10}} />
            <h6 className="text-white" style={{fontSize : '0.9rem'}}>WSO Verifications</h6>
          </div>
      </Link>
        <Link to="/" className="gx-site-logo-small">

             <img src={"http://greenwichtc.com/assets/images/Logos/WSO-logo.png"} alt="loco" style={{width : '40px', height : '35px'}} /> 
          </Link>
    </div>
  );
};

export default SidebarLogo;
