import React from 'react'
import Navbar from './components/Navbar'
import Verification from './components/Verification'
import VerificationForm from './components/VerificationForm'
import bgGreen from 'assets/images/green-bg.png'
import { Link } from "react-router-dom";
const Index = () => {
    return (
        <div style={{ paddingBottom: 50 }}>
            <div className="gx-position-relative">
                <img src={'https://nipswebtech.com/wp-content/uploads/2021/02/banner_003.jpg'} className="position-absolute gx-w-100" style={{ top: 0, left: 0, right: 0, height: '320px', objectFit: 'cover' }} />
                <div className="overlay-image"></div>
                <div className="gx-container position-relative gx-pt-3" style={{ zIndex: 99, height: 350 }}>
                    <Link to="/">

                        <img src="http://greenwichtc.com/assets/images/Logos/WSO-logo.png" alt="loco" style={{ width: '90px', height: '90px', }} />
                    </Link>

                    <p className="veri">World Safety Organization – Certificate Parchments Verification</p>
                </div>
            </div>
            <div className="main-container m-3 m-sm-auto">
                <h2 className="gx-mb-2">Welcome to the World Safety Organization Verifications page</h2>
                <p>Please enter the correct details in the following form to complete the verification.</p>
                <p>All information can be found on the student's certificate and must be entered <strong></strong>exactly as written.</p>
                <VerificationForm />
            </div>

        </div>
    )
}

export default Index
