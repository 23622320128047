import React, { useState } from 'react';
import { Modal, Button, Form, Input, Spin, Row, Col, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { DataRequestAction } from 'redux/actions/CommonHttp'
import { useHistory } from 'react-router-dom';
import { setInitUrl } from 'redux/actions/Auth'
import { MailOutlined, LockOutlined } from '@ant-design/icons';

const SignIn = (props) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const authProps = useSelector(state => state.auth)
    const { loginStart, loginSuccess, loginFail } = authProps



    const onFinish = (values) => {
        console.log('Success:', values);
        dispatch(DataRequestAction('POST', 'login', 'login', values, 'loginStart'));
    };

    if (loginSuccess) {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        dispatch({
            type: 'update_auth'
        })
        dispatch(setInitUrl('/students'));
        history.push('/students')


    }
    if (loginFail) {
        message.error('Incorrect Username or password')
        dispatch({
            type: 'update_auth'
        })
    }

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-login-content">
                        <div className="gx-text-center gx-mb-4">
                            <img src="http://greenwichtc.com/assets/images/Logos/WSO-logo.png" alt="loco" style={{ width: '120px', height: '120px', }} />
                        </div>

                        <Form
                            initialValues={{ remember: true }}
                            name="login-form"
                            onFinish={onFinish}
                            className="gx-signin-form gx-form-row0">


                            <Form.Item
                                name="email"
                                className="gx-p-0 gx-m-0 gx-mb-3"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input placeholder="Email" prefix={<MailOutlined className="gx-text-primary" style={{
                                    marginRight: '5px'
                                }} />} />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                className="gx-p-0 gx-m-0 gx-mb-2"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input
                                    type="password"
                                    placeholder="Password"
                                    prefix={<LockOutlined className="gx-text-primary" style={{
                                        marginRight: '5px'
                                    }} />}
                                />
                            </Form.Item>

                            <Form.Item>
                                {!loginStart && <Button type="primary" className="gx-mb-0 gx-w-100 pt-0" htmlType="submit">
                                    Login
                                </Button>}
                                {loginStart && <Spin><Button type="primary" className="gx-mb-0 gx-w-100 pt-0">
                                    Submit
                                    </Button></Spin>}


                            </Form.Item>

                        </Form>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SignIn;
