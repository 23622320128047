import React from "react";
import Widget from "components/Widget";
import CategoryList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import TableAction from 'components/Table/Actions'
import Layout from 'components/LayoutForPortal'
import SearchBox from 'components/Filters/SearchBox'
import AddNewStudentForm from './components/AddNewStudentForm'
import EditStudent from './components/EditStudent'
import DeleteConfirm from 'components/Modal/DeleteConfirm';
import { useDispatch, useSelector } from 'react-redux'
import { intlPlaceholder } from 'util/intlPlaceholder'
import { useHistory } from 'react-router-dom'
import {renderDate} from 'utils/commonUseFunction'
const Index = () => {

    const curdProps = useSelector(state => state.CrudR)
    const { Edit_Student_Modal } = curdProps
    const history = useHistory()

    const dispatch = useDispatch()

    const columns = [
        // {
        //     title: 'ID',
        //     render: (record) => <span>{record.studentId}</span>,
        //     key: "studentId",
        // },
        {
            title: 'Student Name',
            render: (record) => <span>{record.studentName}</span>,
            key: "name",
        },
        {
            title: 'Certificate Number',
            render: (record) => <span>{record.certificateNumber}</span>,

            key: "certificateNumber",
        },
        {
            title: 'Created At',
            render: (record) => <span>{renderDate(record.createdAt,'MMMM Do YYYY hh:mm a')}</span>,

            key: "createdAt",
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            key: "action",
            width: '50px'
        },
    ];
    const renderActions = (record) => {
        return <div className="gx-d-flex gx-flex-column">
            <span className="action-link first gx-link" onClick={() => {
                dispatch({

                    type: 'Show_Modal',
                    payload: record,
                    ModalName: "Edit_Student_Modal"
                })
            }}> Edit</span>

            <DeleteConfirm
                type="deleteStudent"
                recordName={'studentList'}
                method="DELETE"
                selectedItem={record}
                apiRequest="DeleteRecord"
                buttonName="Yes"
                oKText='Yes'
                title={'Do you want to delete this student?'}
                content=""
                Icon={<span style={{ fontSize: '14px' }}>Delete</span>}
            />
        </div>
    }


    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold"
                        style={{ color: "#14244E" }}>
                        Students
                    </h4>
                }
                extra={
                    <div className="gx-d-flex gx-flex-column gx-flex-sm-row">
                        <div className="gx-my-3 gx-my-sm-0" style={{ width: '280px', marginRight: '12px' }}>
                            <SearchBox recordName="studentList" placeholder="Student name or certificate no" apiName="getStudent" requestType="FetchRecord" />
                        </div>
                        <AddNewStudentForm />
                    </div>
                }
            >

                <CategoryList recordName="studentList" apiName="getStudent" columns={columns} />
            </Widget>
            {Edit_Student_Modal && <EditStudent />}
        </Layout>
    );
};

export default Index;