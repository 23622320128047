import React, { useState } from "react"
import { DataRequestAction } from "redux/actions/CommonHttp"
import SimpleModal from "components/Modal/SimpleModal"
import { useDispatch, useSelector } from "react-redux"
import IntlMessages from "util/IntlMessages"
import { Form, Input, Row, Col, Button, Spin } from "antd"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
const EditProfile = () => {
	const [phoneNumber, setPhoneNumber] = useState()
	const settingsProps = useSelector((state) => state.auth)
	const { settingsData, updatingProfile } = settingsProps

	const curdProps = useSelector((state) => state.CrudR)
	const { EditProfile_Modal } = curdProps
	const dispatch = useDispatch()

	const openEditProfile = () => {
		dispatch({
			ModalName: "EditProfile_Modal",
			type: "Show_Modal",
			payload: null,
		})
	}
	const closeEditProfile = () => {
		dispatch({
			ModalName: "EditProfile_Modal",
			type: "Hide_Modal",
			payload: null,
		})
	}

	const updateProfle = (values) => {
		dispatch(DataRequestAction("PUT", "updateProfile", "updateProfile", values, "updatingProle", "EditProfile_Modal"))
	}

	console.log("test")

	return (
		<div>
			<span style={{ color: "grey" }} className="icon icon-edit gx-link" onClick={() => openEditProfile()}></span>
			<SimpleModal
				width={500}
				title={
					<span style={{ color: "#041649" }} className="gx-font-sans-bold">
						{<IntlMessages id="settings.editModal.updateProfile" />}{" "}
					</span>
				}
				modalFor="EditProfile_Modal"
				visible={EditProfile_Modal ? EditProfile_Modal : false}
			>
				<span></span>
				<Form layout="vertical" name="editProfile" onFinish={updateProfle} initialValues={settingsData && settingsData}>
					<Row className="gx-d-flex gx-flex-row gx-align-items-center">
						<Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pl-0">
							<Form.Item
								name="firstName"
								label="First Name"
								rules={[
									{
										required: true,
										message: "Please enter first name",
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pl-0">
							<Form.Item
								name="lastName"
								label="Last Name"
								rules={[
									{
										required: true,
										message: "Please enter last name",
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pl-0">
							<Form.Item
								name="email"
								className="lower-case-email"
								label={<IntlMessages id="settings.editModal.email" />}
								rules={[
									{
										type: "email",
										required: true,
										message: "Please enter email",
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pl-0">
							<Form.Item
								name="phoneNumber"
								className="gx-mt-4 gx-mb-4"
								style={{
									marginLeft: "0px",
									marginRight: "0px",
								}}
								rules={[{ required: true, message: "Please insert a valid number!" }]}
							>
								<PhoneInput
									inputStyle={{
										width: "100%",
									}}
									country={"pk"}
									regions={"asia"}
									value={phoneNumber}
									onChange={(phone) => setPhoneNumber({ phone })}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
							<div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end ">
								<Form.Item className="gx-mb-0">
									<Button
										type="default"
										className="gx-pt-0 gx-mb-0 gx-mr-2"
										htmlType="button"
										onClick={() => closeEditProfile()}
									>
										<IntlMessages id="settings.editModal.cancel" />
									</Button>
								</Form.Item>
								<Form.Item className="gx-mb-0">
									{updatingProfile && (
										<Spin>
											{" "}
											<Button type="primary" className="gx-pt-0 gx-mb-0" htmlType="button" disbaled>
												<IntlMessages id="settings.editModal.save" />
											</Button>
										</Spin>
									)}

									{!updatingProfile && (
										<Button type="primary" className="gx-pt-0 gx-mb-0" htmlType="submit">
											<IntlMessages id="settings.editModal.save" />
										</Button>
									)}
								</Form.Item>
							</div>
						</Col>
					</Row>
				</Form>
			</SimpleModal>
		</div>
	)
}

export default EditProfile
