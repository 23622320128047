import React from 'react'
import { Form, Input, Button, Spin, message,notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { DataRequestAction } from 'redux/actions/CommonHttp'
import {useHistory} from 'react-router-dom'
const VerificationForm = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm()
    const history = useHistory()
    const authProps = useSelector(state => state.auth)
    const { userVerifyError, userVerifySpinner, userVerifySuccess, verifyResult } = authProps
    const onFinish = (values) => {
        console.log('Success:', values);
        dispatch(DataRequestAction('POST', 'verifyUser', 'verifyUser', values, 'verifyingUser'))
    };

    console.log('========verifyResult========',verifyResult)

    const openNotificationWithIcon = (type,text) => {
        notification[type]({
          message: type === 'error' ? 'Error' : 'Success',
          description:text,
        });
      };

    if (userVerifySuccess) {

     

        if (verifyResult && verifyResult.length > 0) {
            openNotificationWithIcon('success','You are verified!')
            form.resetFields()
           
            history.push({ pathname : 'studentDetail', state :verifyResult && verifyResult[0] })
            
        }
        else if (verifyResult && verifyResult.length === 0) {
            openNotificationWithIcon('error','Oops! Your record does not exist in our database')
        }

        dispatch({
            type: 'update_auth'
        })
    }
    if (userVerifyError) {
        openNotificationWithIcon('error','There is a serve issue. try again!')
        dispatch({
            type: 'update_auth'
        })
    }

    return (
        <div className="mt-4">
            <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                form={form}
            >
                <Form.Item
                    label="Certificate No"
                    name="certificateNumber"
                    rules={[{ required: true, message: 'Please input your certificate no!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Name on the certificate"
                    name="studentName"
                    rules={[{ required: true, message: 'Please input your name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item >
                    <div className="d-flex justify-content-end">
                        {!userVerifySpinner && <Button type="primary" className="pt-0 mb-0" htmlType="submit">
                            Submit
                        </Button>}
                        {userVerifySpinner && <Spin> <Button type="primary" className="pt-0 mb-0" htmlType="submit">
                            Submit
                        </Button> </Spin>}
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}

export default VerificationForm
