import React from 'react'
import { Form, Input, Row, Col,InputNumber } from 'antd';



const StudentForm = () => {
    return (
        <div>
            <Row className="gx-mx-0 gx-d-flex">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        name="studentName"
                        label="Student Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter student name!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        name="certificateNumber"
                        label="Certificate No"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter certificate no!'
                            },
                        ]}
                    >
                        <Input style={{width : '100%'}} />
                    </Form.Item>
                </Col>
            </Row>

        </div>
    )
}

export default StudentForm
