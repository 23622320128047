
import categoryIcon from 'assets/images/categoryIcon.png'
const MenuList = {

    "superAdmin": [
    {
        "title": 'leftMenu.student',
        "url": "/students",
        "icon": "dasbhoard",
        "top": '4px',
        "permissionKey": "students",
        "key" : "students"
    },
    {
        "title": 'leftMenu.setting',
        "url": "/settings",
        "icon": "setting",
        "top": '4px',
        "permissionKey": "tasks",
        "key" : "settings"
    },
    

    ],
}

export default MenuList;