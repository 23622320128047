import React from 'react'
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import FormModal from "../../../../components/Modal/FormModal";
import IntlMessages from '../../../../util/IntlMessages'
import StudentForm from './StudentForm'


const AddNewStudentForm = () => {
    
    const dispatch = useDispatch()
    const curdProps = useSelector(state => state.CrudR)
    const { Add_Student_Modal, Loader } = curdProps
   
    return (
        <div>
            <Button
                className='gx-pt-0'
                type="primary"
                onClick={() => {
                    dispatch({
                        type: 'Show_Modal',
                        payload: null,
                        ModalName: "Add_Student_Modal"
                    })
                }}
            >
                Add Student
            </Button>
            <FormModal
                modalTitle={<p className="gx-text-primary"> Add Student </p>}
                basicButtonTitle="Reset Password"
                submitButtonText='Add'
                cancelButtonText='Cancel'
                apiMethod="POST"
                addApiName="addStudent"
                addApiRequest="AddRecord"
                apiLoader="StartSpinner"
                ModalName="Add_Student_Modal"
                confirmLoading={Loader}
                ModalType="Hide_Modal"
                visible={Add_Student_Modal ? Add_Student_Modal : false}
                buttonStyleClass="gx-buttonStyleClass"
                isShowButton={false}
                width={520}
                className="doctor-profile-modal"
                recordName={'studentList'}
            >
                <StudentForm />
            </FormModal>
        </div>

    )
}

export default AddNewStudentForm
