import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import {
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "constants/ThemeSetting";
import IntlMessages from "util/IntlMessages";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import MenuList from './Menu'
const SubMenu = Menu.SubMenu;


const SidebarContent = () => {

  let { navStyle, themeType } = useSelector(({ settings }) => settings);

  const logInUser = useSelector(state => state.auth.authUser);
  // const messageProps = useSelector(state => state.MessagesR);
  const role = logInUser && logInUser.role;
  const MenuData = MenuList[role];
  let {pathname,pathname1} = useSelector(state => state.commonR);

  console.log('=========pathname=========',pathname)

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[0];

  console.log('=========selectedKeys=========',selectedKeys)

  const singleMenuItem = (Item) => {
    if (Item.icon || Item.icon === "") {
      return <Menu.Item key={Item.key}>
        <Link to={Item.url}><i className={`icon icon-${Item.icon}`} />
          <span><IntlMessages id={Item.title} /></span></Link>
      </Menu.Item>
    }
    else if (Item.img) {
      return <Menu.Item key={Item.key}>
        <Link to={Item.url}><img src={Item.img} style={{ marginRight: '20px' }} width={Item.width} height={Item.height} alt=""></img>
          <span><IntlMessages id={Item.title} /></span></Link>
      </Menu.Item>
    }


  }

  const renderSubMenu = (Item) => {
    return <SubMenu key={Item.key} popupClassName={getNavStyleSubMenuClass(navStyle)} title={
      <span>
        <i className={`icon icon-${Item.icon}`} />
        <span><IntlMessages id={Item.title} /></span>
      </span>}>
      {Item.subMenu.map(x => {
        return singleMenuItem(x)
      })}
      
    </SubMenu>
  }

  const renderMenu = () => {


    return MenuData.map(Item => {

      if (Item.subMenu) {
        return (
          renderSubMenu(Item)
        )
      } else {
        return singleMenuItem(Item)
      }

    })

  }

  return (
    <>

      <div className="gx-sidebar-content">
        <SidebarLogo />
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            {renderMenu()}
            {/* <Menu.Item key="dashboard">
              <Link to="/dashboard"><i className="icon icon-dasbhoard" />
                <span><IntlMessages id="leftMenu.dashboard" /></span></Link>
            </Menu.Item>

            <Menu.Item key="user">
              <Link to="/user"><i className="icon icon-apps" />
                <span><IntlMessages id="leftMenu.user" /></span></Link>
            </Menu.Item>

            <Menu.Item key="pharmacy">
              <Link to="/pharmacy"><i className="icon icon-card" />
                <span><IntlMessages id="leftMenu.pharmacy" /></span></Link>
            </Menu.Item>
            <SubMenu key="product" popupClassName={getNavStyleSubMenuClass(navStyle)} title={
              <span>
                <i className="icon icon-feedback" />
                <span>Product</span>
              </span>}>
              <Menu.Item key="product/approvedProduct">
                <Link to="/product/approvedProduct">
                  <span>All Product</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="product/pendingProduct">
                <Link to="/product/pendingProduct">
                  <span>Pending Product</span></Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="category">
              <Link to="/category"><i className="icon icon-dasbhoard" />
                <span><IntlMessages id="leftMenu.category" /></span></Link>
            </Menu.Item>
            <Menu.Item key="settings">
              <Link to="/settings"><i className="icon icon-setting" />
                <span><IntlMessages id="leftMenu.setting" /></span></Link>
            </Menu.Item> */}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

