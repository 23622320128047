import React from "react";
import { Route, Switch } from "react-router-dom";
import Student from'./Admin/Student'

import Settings from './Admin/Setting'


const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route exact path={`${match.url}students`} component={Student} />

      <Route exact path={`${match.url}settings`} component={Settings} />
    </Switch>
  </div>
);

export default App;
