





import React from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";
const Index = () => {


    const location = useLocation()
    const { state: { studentName, certificateNumber } } = location


    return (
        <div style={{ paddingBottom: 50 }}>
            <div className="gx-position-relative">
                <img src={'https://nipswebtech.com/wp-content/uploads/2021/02/banner_003.jpg'} className="position-absolute gx-w-100" style={{ top: 0, left: 0, right: 0, height: '320px', objectFit: 'cover' }} />
                <div className="overlay-image"></div>
                <div className="gx-container position-relative gx-pt-3" style={{ zIndex: 99, height: 350 }}>
                    <Link to="/">

                        <img src="http://greenwichtc.com/assets/images/Logos/WSO-logo.png" alt="loco" style={{ width: '90px', height: '90px', }} />
                    </Link>

                    <p className="veri">World Safety Organization – Certificate Parchments Verification</p>
                </div>
            </div>
            <div className="main-container m-3 m-sm-auto">
                <div >
                    <h1>Success</h1>
                    <h4>You have been verified successfully</h4>
                    <p>Name : {studentName}</p>
                    <p>Certificate Number : {certificateNumber}</p>

                </div>
            </div>

        </div>
    )
}

export default Index
