import React from 'react'
import { useSelector } from 'react-redux'
import FormModal from "../../../../components/Modal/EditFormModal";

import StudentForm from './StudentForm'


const EditStudent = () => {

    const curdProps = useSelector(state => state.CrudR)
    const { Edit_Student_Modal, Loader,initialValues } = curdProps

   
    return (
        <div>


            <FormModal
               modalTitle={<p className="gx-text-primary"> Update Student Details </p>}
               basicButtonTitle="Reset Password"
                submitButtonText="Update"
                cancelButtonText="Cancel"
                editApiRequest="EditRecord"
                apiLoader="StartSpinner"
                editApiMethod="PUT"
                editApiName="editStudent"
                ModalName="Edit_Student_Modal"
                confirmLoading={Loader}
                ModalType="Hide_Modal"
                visible={Edit_Student_Modal ? Edit_Student_Modal : false}
                buttonStyleClass="gx-buttonStyleClass"
                isShowButton={false}
                initialValues={initialValues}
                width={520}
                className="doctor-profile-modal"
                recordName={'studentList'}
            >
                <StudentForm />
            </FormModal>
        </div>

    )
}

export default EditStudent
